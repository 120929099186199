import { useSearchParams } from 'react-router-dom';
import TablebillLabs from '@/components/Tables/TablebillLabs';
import { useFetchBillLabs } from '@/hooks/useBill';
import { Button } from '@/components/ui/button';
import { useFetchBillOutsourcingBuyers } from '@/hooks/useBillOutsourcingBuyer';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import TablebillLabOutsourcings from '@/components/Tables/TablebillLabOutsourcings';
import ThinkingAnimation from '@/components/ThinkingAnimation';
const BillLabs = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { billLabs, loading, totalBillLabs } = useFetchBillLabs(searchParams);

  const { billOutsourcingBuyers, total } =
    useFetchBillOutsourcingBuyers(searchParams);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-full">
        <ThinkingAnimation />
      </div>
    );
  }

  return (
    <div className="">
      <Tabs defaultValue="cases">
        <TabsList className="grid w-full grid-cols-2">
          <TabsTrigger value="cases">Oklusion</TabsTrigger>
          <TabsTrigger value="outsorcings">Laboratorios</TabsTrigger>
        </TabsList>
        <TabsContent value="cases">
          <TablebillLabs
            billLabs={billLabs}
            total={totalBillLabs}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
        </TabsContent>
        <TabsContent value="outsorcings">
          <TablebillLabOutsourcings
            billOutsourcingBuyers={billOutsourcingBuyers}
            total={total}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            roleType={'user'}
          />
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default BillLabs;
