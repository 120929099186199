import React, { useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { createDoc } from '@/services/doc';
//import { createFileDoc } from '@/services/file';
import { toast } from 'react-toastify';
import { modules } from '@/constants/QuillEditor';
import ThinkingAnimation from '@/components/ThinkingAnimation';

const Font = Quill.import('formats/font');
Font.whitelist = ['sans-serif', 'monospace', 'serif'];
Quill.register(Font, true);

const DocCreate = () => {
  const [title, setTitle] = useState('');
  const [slug, setSlug] = useState('');
  const [content, setContent] = useState('');
  const [order, setOrder] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleSave = async () => {
    if (!title || !slug || !content) {
      toast.error('Por favor, completa todos los campos requeridos.');
      return;
    }

    setLoading(true);
    try {
      const docData = { title, content, slug, order };
      await createDoc(docData);
      toast.success('Guía creada con éxito');
      // Limpiar los campos
      setTitle('');
      setSlug('');
      setContent('');
      setOrder(0);
    } catch (error) {
      console.error('Error creating doc:', error);
      const errorMessage =
        error.response?.data?.message || 'Error al crear la guía';
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };
  if (loading) {
    return (
      <div className="flex justify-center items-center h-full">
        <ThinkingAnimation />
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md">
      <h1 className="text-2xl font-semibold mb-6 text-gray-800">Nueva Guía</h1>
      <div className="space-y-6">
        <div>
          <label className="block text-gray-700 font-medium mb-2">Título</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            required
          />
        </div>
        <div>
          <label className="block text-gray-700 font-medium mb-2">Slug</label>
          <input
            type="text"
            value={slug}
            onChange={(e) => setSlug(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            required
          />
        </div>
        <div>
          <label className="block text-gray-700 font-medium mb-2">Orden</label>
          <input
            type="number"
            min={0}
            value={order}
            onChange={(e) => setOrder(Number(e.target.value))} // Convertir a número
            className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>

        <div>
          <label className="block text-gray-700 font-medium mb-2">
            Contenido
          </label>
          <ReactQuill
            value={content}
            onChange={setContent}
            className="bg-white rounded-lg shadow-sm"
            theme="snow"
            modules={modules}
          />
        </div>

        <button
          onClick={handleSave}
          disabled={loading}
          className={`w-full py-3 px-4 ${
            loading ? 'bg-gray-400' : 'bg-blue-500'
          } text-white font-semibold rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2`}
        >
          {loading ? 'Creando...' : 'Crear Guía'}
        </button>
      </div>
    </div>
  );
};

export default DocCreate;
