import { useState, useEffect, useRef } from 'react';
import { useParams, Link, redirect } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Label } from '@/components/ui/label';

import { toast } from 'react-toastify';
import io from 'socket.io-client';
import { updateOrder, getOrderAsAdmin } from '@/services/order';
import { getDemandById, editDemandAsAdmin } from '@/services/demand';
import TeethDetailsCard from '@/components/Cards/TeethDetailsCard';
import StepperOrder from '@/components/Cards/StepperOrder';
import RevisionFilesCard from '@/components/Cards/RevisionFilesCard';
import CaseTypeCardAdmin from '@/components/Cards/CaseTypeCardAdmin';
import useUserStore from '@/store/userStore';
import useDemandStore from '@/store/DemandStore';
import useNotificationStore from '@/store/Notificationstore';
import ThinkingAnimation from '@/components/ThinkingAnimation';

const OrderPageAdmin = () => {
  const { user } = useUserStore();
  const { addNotification } = useNotificationStore();
  const { orderId } = useParams();
  const { demand, setDemand } = useDemandStore();
  const [caseData, setCaseData] = useState(null);
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);
  const [saving, setSaving] = useState(false);
  const socket = useRef(null);

  useEffect(() => {
    const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;
    socket.current = io(API_BASE_URL);
    socket.current.emit('setup', { user });

    // Join the room for this specific chat
    socket.current.emit('joinRoom', orderId);

    const handleReceiveDemand = (demand) => {
      addNotification({
        message: 'Nueva demanda recibida',
        type: 'demand',
        demand: demand._id,
      });
    };
    const handleReceiveOffer = (offer) => {
      addNotification({
        message: 'Nueva oferta recibida',
        type: 'offer',
        offer: offer._id,
      });
    };
    const handleReceiveOrder = (order) => {
      addNotification({
        message: 'Tu oferta fue aceptada',
        type: 'order',
        order: order._id,
      });
    };
    const handleReceiveUpdateOrder = ({ status }) => {
      fetchOrder();
      toast.success(`Pedido actualizado: ${status}`);
      addNotification({
        message: 'Pedido actualizado',
        type: 'order',
      });
    };

    socket.current.on('receiveDemand', handleReceiveDemand);
    socket.current.on('receiveOffer', handleReceiveOffer);
    socket.current.on('receiveOrder', handleReceiveOrder);
    socket.current.on('receiveUpdateOrder', handleReceiveUpdateOrder);

    return () => {
      socket.current.emit('leaveRoom', orderId);
      socket.current.off('receiveDemand', handleReceiveDemand);
      socket.current.off('receiveOffer', handleReceiveOffer);
      socket.current.off('receiveOrder', handleReceiveOrder);
      socket.current.off('receiveUpdateOrder', handleReceiveUpdateOrder);
      socket.current.disconnect();
    };
  }, [orderId]);

  const fetchOrder = async () => {
    try {
      setLoading(true);
      const response = await getOrderAsAdmin(orderId);
      const { order } = response;

      if (!order) {
        toast.error('Error fetching order');
        redirect('/admin/order');
        return;
      }

      setOrder(order);

      if (order.offer) {
        const demandResponse = await getDemandById(order.offer.demand);
        setDemand(demandResponse.demand);
        setCaseData(demandResponse.demand);
      } else {
        toast.error('No offer associated with this order');
        redirect('/admin/order');
      }

      setLoading(false);
    } catch (error) {
      console.error('Error fetching order or demand:', error);
      toast.error('Error loading order');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrder();
  }, [orderId]);

  const handleStatusChange = async (newStatus) => {
    try {
      setLoadingButton(true);

      await updateOrder(orderId, { status: newStatus });
      setLoadingButton(false);
      fetchOrder();

      switch (newStatus) {
        case 'payed':
          socket.current.emit('updateOrder', {
            order,
            status: 'Orden pagada',
            user,
          });
          break;
        case 'onfabrication':
          socket.current.emit('updateOrder', {
            order,
            status: 'Orden en fabricacion',
            user,
          });
          break;
        case 'sended':
          socket.current.emit('updateOrder', {
            order,
            status: 'Orden enviada',
            user,
          });
          break;
        case 'completed':
          socket.current.emit('updateOrder', {
            order,
            status: 'Orden lista',
            user,
          });
          break;
        default:
          break;
      }
    } catch (error) {
      console.error('Error updating order status:', error);
    }
  };

  const renderActionButton = () => {
    if (!order) return null; // Add this check to prevent accessing properties of null

    if (user.role === 'DENTIST_ROLE' && order.status === 'pending') {
      return (
        <button
          type="button"
          className="bg-blue-500 w-80 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-auto"
          onClick={() => handleStatusChange('payed')}
          disabled={loadingButton}
        >
          Pagar
        </button>
      );
    }

    if (user.role === 'SELLER_ROLE') {
      if (order.status === 'payed') {
        return (
          <button
            type="button"
            className="bg-blue-500 w-80 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-auto"
            onClick={() => handleStatusChange('onfabrication')}
            disabled={loadingButton}
          >
            Marcar en fabricación
          </button>
        );
      }

      if (order.status === 'onfabrication') {
        return (
          <button
            type="button"
            className="bg-blue-500 w-80 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-auto"
            onClick={() => handleStatusChange('sended')}
            disabled={loadingButton}
          >
            Marcar como enviado
          </button>
        );
      }
    }

    if (user.role === 'DENTIST_ROLE' && order.status === 'sended') {
      return (
        <button
          type="button"
          className="bg-blue-500 w-80 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-auto"
          onClick={() => handleStatusChange('completed')}
          disabled={loadingButton}
        >
          todo fué OK
        </button>
      );
    }

    return null; // No button displayed for other cases
  };

  const saveDemand = async () => {
    setSaving(true);
    try {
      const response = await editDemandAsAdmin(demand._id, caseData);
      if (response) {
        toast.success('Caso guardado exitosamente');
        fetchOrder();
      }
    } catch (error) {
      console.error('Failed to save demand:', error);
      toast.error('Error al guardar el caso');
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-full">
        <ThinkingAnimation />
      </div>
    );
  }

  return (
    <div className="w-full">
      <div className="flex flex-col gap-2 w-full">
        <div className=" rounded-lg flex flex-col gap-2 w-full">
          <CaseTypeCardAdmin caseData={caseData} setCaseData={setCaseData} />

          <div className="flex flex-col md:flex-row gap-2 justify-around w-full mx-auto">
            {order?.offer && (
              <TeethDetailsCard
                demand={demand}
                offer={order.offer}
                setCaseData={setCaseData}
                user={user}
                className="w-full md:w-1/3"
              />
            )}
            <div className="w-full  md:w-2/3 flex  flex-col  gap-2">
              <StepperOrder order={order} />
              <RevisionFilesCard order={order} />
              {renderActionButton()}
            </div>
          </div>
        </div>
        <Button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
          disabled={saving}
          onClick={saveDemand} // Call the saveDemand function
        >
          {saving ? 'cargando...' : 'Guardar cambios'}
        </Button>
      </div>
    </div>
  );
};

export default OrderPageAdmin;
