import { useSearchParams } from 'react-router-dom';
import TablebillLabCases from '@/components/Tables/TablebillLabCases';
import { useFetchBillLabCases } from '@/hooks/useBillCase';
import ThinkingAnimation from '@/components/ThinkingAnimation';

const MyBills = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { billLabCases, loading, totalBillLabCases } =
    useFetchBillLabCases(searchParams);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-full">
        <ThinkingAnimation />
      </div>
    );
  }
  return (
    <div>
      <section>
        <TablebillLabCases
          billlabcases={billLabCases}
          total={totalBillLabCases}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        />
      </section>
    </div>
  );
};

export default MyBills;
