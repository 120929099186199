import { useSearchParams } from 'react-router-dom';
import TableUsers from '@/components/Tables/TableUsers';
import { useFetchUsers } from '@/hooks/useUser';
import ThinkingAnimation from '@/components/ThinkingAnimation';

const UsersPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { users, total, loading } = useFetchUsers(searchParams);
  if (loading) {
    return (
      <div className="flex justify-center items-center h-full">
        <ThinkingAnimation />
      </div>
    );
  }

  return (
    <div>
      {users ? (
        <TableUsers
          users={users}
          total={total}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
        />
      ) : (
        <p>No users found</p>
      )}
    </div>
  );
};

export default UsersPage;
