import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { getDocById, updateDoc, deleteDoc } from '@/services/doc';
import { toast } from 'react-toastify';
import { modules } from '@/constants/QuillEditor';
import ThinkingAnimation from '@/components/ThinkingAnimation';

const Font = Quill.import('formats/font');
Font.whitelist = ['sans-serif', 'monospace', 'serif'];
Quill.register(Font, true);

const DocEditor = () => {
  const { docId } = useParams();
  const [title, setTitle] = useState('');
  const [slug, setSlug] = useState('');
  const [content, setContent] = useState('');
  const [category, setCategory] = useState('');
  const [order, setOrder] = useState(0);
  const [loading, setLoading] = useState(true); // New loading state
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDoc = async () => {
      try {
        const { doc } = await getDocById(docId);
        setTitle(doc.title);
        setContent(doc.content);
        setCategory(doc.category);
        setSlug(doc.slug);
        setOrder(doc.order);
      } catch (error) {
        console.error('Error fetching doc:', error);
        toast.error('Error al cargar la guía');
      } finally {
        setLoading(false);
      }
    };

    if (docId) {
      fetchDoc();
    }
  }, [docId]);

  const handleSave = async () => {
    if (!title || !slug || !content) {
      toast.error('Todos los campos son obligatorios');
      return;
    }

    try {
      const docData = { title, content, slug, order };
      await updateDoc(docId, docData);
      toast.success('Guía actualizada con éxito');
      navigate(`/doc/${slug}`); // Redirigir a la guía actualizada
    } catch (error) {
      console.error('Error updating doc:', error);
      toast.error('Error al actualizar la guía');
    }
  };
  const handleDelete = async () => {
    try {
      await deleteDoc(docId);
      toast.success('Guía eliminada con éxito');
      navigate('/docs'); // Redirigir a la lista de guías
    } catch (error) {
      console.error('Error deleting doc:', error);
      toast.error('Error al eliminar la guía');
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-full">
        <ThinkingAnimation />
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md">
      <h1 className="text-2xl font-semibold mb-6 text-gray-800">Editar Guía</h1>
      {loading ? (
        <p>Cargando...</p> // Loading indicator
      ) : (
        <div className="space-y-6">
          <div>
            <label className="block text-gray-700 font-medium mb-2">
              Título
            </label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              required
            />
          </div>
          <div>
            <label className="block text-gray-700 font-medium mb-2">Slug</label>
            <input
              type="text"
              value={slug}
              onChange={(e) => setSlug(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              required
            />
          </div>
          <div>
            <label className="block text-gray-700 font-medium mb-2">
              Orden
            </label>
            <input
              type="number"
              min={0}
              value={order}
              onChange={(e) => setOrder(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
          <div>
            <label className="block text-gray-700 font-medium mb-2">
              Contenido
            </label>
            <ReactQuill
              value={content}
              onChange={setContent}
              className="bg-white rounded-lg shadow-sm"
              theme="snow"
              modules={modules}
            />
          </div>
          <button
            onClick={handleSave}
            className="w-full py-3 px-4 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            Guardar Documento
          </button>
          <button
            onClick={handleDelete}
            className="w-full py-3 px-4 bg-red-500 text-white font-semibold rounded-lg shadow-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
          >
            Eliminar Documento
          </button>
        </div>
      )}
    </div>
  );
};

export default DocEditor;
