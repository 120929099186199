import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { toast } from 'react-toastify';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { getBillingDataAsAdmin } from '@/services/billOutsourcingBuyer';
import { createbillLab } from '@/services/billLab';
import useUserStore from '@/store/userStore';
import io from 'socket.io-client';
import ThinkingAnimation from '@/components/ThinkingAnimation';

const CaseBillPage = () => {
  const { user } = useUserStore();
  const { caseId } = useParams();
  const navigate = useNavigate();
  const [billingData, setBillingData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const socket = useRef(null);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const { billData } = await getBillingDataAsAdmin(caseId);
      setBillingData(billData);
    } catch (error) {
      toast.error('Error al cargar los datos de facturación');
    } finally {
      setLoading(false);
    }
  }, [caseId]);

  useEffect(() => {
    const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;
    socket.current = io(API_BASE_URL);
    socket.current.emit('setup', { user });
    socket.current.emit('joinRoom', caseId);

    return () => {
      socket.current.emit('leaveRoom', caseId);
      socket.current.disconnect();
    };
  }, [caseId, user]);

  const handleFileUpload = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setError(null);
    } else {
      setError('Seleccione un archivo válido');
    }
  };

  const handleAccept = async (e) => {
    e.preventDefault();
    if (!file) return toast.error('Debe seleccionar al menos un archivo');

    try {
      const billLabData = {
        file,
        amount:
          billingData?.outsourcing?.selectedLaboratorios[0]?.offer?.commission,
      };
      const { billLab } = await createbillLab({
        billLabCaseId: billingData.outsourcing.billLabCase._id,
        billLabData,
      });
      if (billLab) {
        toast.success('Factura creada correctamente');
        navigate('/admin/billLabs');
      }
    } catch (error) {
      toast.error('Error al aceptar el caso');
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-full">
        <ThinkingAnimation />
      </div>
    );
  }

  if (!billingData) {
    return (
      <div className="flex justify-center items-center min-h-screen font-inter">
        <h1 className="text-2xl font-bold">Caso no encontrado</h1>
      </div>
    );
  }

  return (
    <div className="casePage">
      <div className="flex flex-col gap-3">
        <AcceptForm
          handleAccept={handleAccept}
          handleFileUpload={handleFileUpload}
          file={file}
          error={error}
          billingData={billingData}
        />
      </div>
    </div>
  );
};

const AcceptForm = ({
  billingData,
  handleAccept,
  handleFileUpload,
  file,
  error,
}) => (
  <Card className="py-4 px-2 gap-8 rounded-lg bg-white ">
    <CardHeader>
      <CardTitle>Factura para el laboratorio </CardTitle>
    </CardHeader>
    <CardContent className="w-full flex flex-col ">
      <div className="bg-gray-100 p-4 rounded-lg shadow-md mb-6 w-full">
        <div className="  text-sm text-gray-700 flex  flex-col md:flex-row gap-4 justify-between">
          {/* Billing details */}
          <div>
            <p>
              <strong>Nombre del laboratorio:</strong>{' '}
              {billingData?.outsourcing?.billLabCase?.seller?.labName || 'N/A'}
            </p>
            <p>
              <strong>Nombre:</strong>{' '}
              {billingData?.outsourcing?.billLabCase?.seller?.name}{' '}
              {billingData?.outsourcing?.billLabCase?.seller?.lastname || 'N/A'}
            </p>
            <p>
              <strong>Razón Social:</strong>{' '}
              {billingData?.outsourcing?.billLabCase?.seller?.razonSocial ||
                'N/A'}
            </p>
            <p>
              <strong>NIF:</strong>{' '}
              {billingData?.outsourcing?.billLabCase?.seller?.nif || 'N/A'}
            </p>
            <p>
              <strong>Teléfono:</strong>{' '}
              {billingData?.outsourcing?.billLabCase?.seller?.phone || 'N/A'}
            </p>
            <p>
              <strong>Monto a facturar por comisión:</strong> €{' '}
              {billingData?.outsourcing?.selectedLaboratorios[0]?.offer
                ?.commission || 'N/A'}
            </p>
          </div>

          <div>
            {/* Address details */}
            <h4 className="font-semibold mt-4">Dirección</h4>
            <p>
              {billingData?.outsourcing?.billLabCase?.seller?.clinics[0]
                ?.clinicstreet || 'N/A'}
            </p>
            <p>
              {billingData?.outsourcing?.billLabCase?.seller?.clinics[0]
                ?.postalCode || 'N/A'}
            </p>
            <p>
              {billingData?.outsourcing?.billLabCase?.seller?.clinics[0]
                ?.province || 'N/A'}
            </p>
            <p>
              {billingData?.outsourcing?.billLabCase?.seller?.clinics[0]
                ?.city || 'N/A'}
            </p>
          </div>
        </div>
      </div>

      {/* File upload form */}
      <form onSubmit={handleAccept} className="flex flex-col gap-2">
        <label htmlFor="file" className="font-semibold">
          Subir archivo:
        </label>
        <Input
          id="file"
          type="file"
          onChange={handleFileUpload}
          className="border rounded p-2"
        />
        {error && <p className="text-red-500">{error}</p>}
        {file && <p>Archivo seleccionado: {file.name}</p>}
        <Button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full"
          disabled={!file}
        >
          Crear factura
        </Button>
      </form>
    </CardContent>
  </Card>
);

export default CaseBillPage;
