import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import TableReferrals from '@/components/Tables/TableReferrals';
import { useFetchReferrals, useCreateReferral } from '@/hooks/useReferral';
import ThinkingAnimation from '@/components/ThinkingAnimation';

const MyReferrals = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [email, setEmail] = useState('');
  const { referrals, loading, total } = useFetchReferrals(searchParams);
  const { link, handleCreateReferralLink } = useCreateReferral();

  if (loading) {
    return (
      <div className="flex justify-center items-center h-full">
        <ThinkingAnimation />
      </div>
    );
  }

  return (
    <div className="w-full mx-auto mt-8 p-2 md:p-4">
      <div className="flex flex-col items-center justify-center">
        <input
          type="email"
          placeholder="correo"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="border border-gray-300 rounded-md px-3 py-2 mb-4 w-full max-w-md"
        />
        <button
          onClick={() => handleCreateReferralLink(email)}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded-md mb-4"
          disabled={!email}
        >
          Crea un link de referido
        </button>

        {link && email && (
          <div className="flex flex-row items-center gap-4 mt-4">
            <p className="text-center mb-2 font-semibold">
              {`${window.location.origin}/register?referralCode=${link}`}
            </p>
            <button
              onClick={() =>
                navigator.clipboard.writeText(
                  `${window.location.origin}/register?referralCode=${link}`,
                )
              }
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-6 rounded-md"
            >
              Copiar link
            </button>
          </div>
        )}
      </div>

      <TableReferrals
        users={referrals}
        total={total}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
      />
    </div>
  );
};

export default MyReferrals;
