import React, { useEffect, useState } from 'react';
import { getGuidecategories } from '@/services/guide';
import ThinkingAnimation from '@/components/ThinkingAnimation';

const GuideHome = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const { categories } = await getGuidecategories({ queryString: '' });
        setCategories(categories);
      } catch (error) {
        console.error('Error fetching categories:', error);
        setError('Error al cargar las categorías'); // Set an error message
      } finally {
        setLoading(false); // Stop loading regardless of success or error
      }
    };

    fetchCategories();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-full">
        <ThinkingAnimation />
      </div>
    );
  }
  if (error) return <div>{error}</div>; // Show error state

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md">
      <h1 className="text-3xl font-bold mb-4 text-gray-800">
        Bienvenido a la Sección de Guías
      </h1>
      <p className="text-lg mb-6 text-gray-700">
        Aquí encontrarás una variedad de guías útiles que te ayudarán a
        comprender mejor los diferentes procesos y servicios disponibles. Navega
        por las categorías a continuación para encontrar la guía que mejor se
        adapte a tus necesidades.
      </p>

      <h2 className="text-2xl font-semibold mb-2 text-gray-800">
        Categorías de Guías:
      </h2>
      {categories.length > 0 ? (
        <ul className="list-disc list-inside space-y-2">
          {categories.map((category) => (
            <li key={category._id} className="text-gray-600">
              {category.name}
            </li>
          ))}
        </ul>
      ) : (
        <p className="text-gray-600">No hay categorías disponibles.</p>
      )}
    </div>
  );
};

export default GuideHome;
