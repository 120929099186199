import { useSearchParams } from 'react-router-dom';
import TablebillLabs from '@/components/Tables/TablebillLabs';
import { useFetchBillLabs } from '@/hooks/useBill';
import ThinkingAnimation from '@/components/ThinkingAnimation';

const BillLabsAdmin = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { billLabs, loading, totalBillLabs } = useFetchBillLabs(searchParams);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-full">
        <ThinkingAnimation />
      </div>
    );
  }

  return (
    <div className="">
      <section>
        <TablebillLabs
          billLabs={billLabs}
          total={totalBillLabs}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        />
      </section>
    </div>
  );
};

export default BillLabsAdmin;
