import { useSearchParams } from 'react-router-dom';
import ReviewCard from '@/components/Cards/ReviewCard';
import { useFetchReviews } from '@/hooks/useReview';
import ThinkingAnimation from '@/components/ThinkingAnimation';

const MyReviews = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { reviews, loading } = useFetchReviews(searchParams);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-full">
        <ThinkingAnimation />
      </div>
    );
  }
  return (
    <div>
      <div className="flex flex-col gap-4 mt-4">
        {reviews && reviews.length > 0 ? (
          reviews.map((review) => (
            <ReviewCard key={review._id} review={review} />
          ))
        ) : (
          <p className="text-gray-500">No reviews available yet.</p>
        )}
      </div>
    </div>
  );
};

export default MyReviews;
