import { useSearchParams } from 'react-router-dom';
import NotificationCard from '@/components/Cards/NotificationCard';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Button } from '@/components/ui/button';
import { useFetchNotifications } from '@/hooks/useNotification';
import ThinkingAnimation from '@/components/ThinkingAnimation';

const Notifications = () => {
  const [searchParams] = useSearchParams();
  const { notifications, loading, total } = useFetchNotifications(searchParams);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-full">
        <ThinkingAnimation />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4 h-full p-4">
      <h1 className="font-bold text-lg">Notificaciones</h1>
      <ScrollArea className="flex-grow overflow-y-auto px-4 h-screen">
        <ul className="flex flex-col gap-2">
          {notifications.length === 0 ? (
            <p>No hay notificaciones.</p>
          ) : (
            notifications.map((notification) => (
              <li key={notification.id}>
                <NotificationCard notification={notification} />
              </li>
            ))
          )}
        </ul>
      </ScrollArea>
      {notifications.length < total && (
        <div className="flex justify-center mt-4">
          <Button onClick={fetchData} variant="outline">
            Cargar más
          </Button>
        </div>
      )}
    </div>
  );
};

export default Notifications;
